









import { Component } from 'vue-property-decorator'
import Base from '@/application/models/Base'
import ManagePatient from '@/components/manage/custom/ManagePatient.vue'
import ManageHospitals from '@/components/manage/custom/ManageHospitals.vue'
import ManagePatientDataProvider from '@/components/manage/custom/ManagePatientDataProvider.vue'

@Component({
  components: { ManagePatientDataProvider, ManageHospitals, ManagePatient }
})
export default class Manage extends Base {
  constructor () {
    super('manage')
  }
}
