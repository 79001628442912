




































import Base, { Form } from '@/application/models/Base'
import { Component, Ref } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import PatientDataProvider from '@/application/models/PatientDataProvider'
import Tooltip from '@/components/common/Tooltip.vue'

@Component({
  components: {
    Tooltip
  }
})
export default class AddPatientDataProvider extends Base {
  // store
  @Action('CREATE_PATIENTDATAPROVIDER', { namespace: 'patientDataProvider' })
  readonly createPatientDataProvider!: (patientDataProvider: PatientDataProvider) => Promise<PatientDataProvider>

  // controll
  dialog = false
  @Ref('form')
  readonly form!: Form

  // data
  formData = {
    dataProviderName: ''
  }

  constructor () {
    super('manage.add-patient-sata-provider')
  }

  send () {
    if (this.form.validate()) {
      this.dialog = false
      this.createPatientDataProvider({
        id: '',
        name: this.formData.dataProviderName
      })
    }
  }

  close () {
    this.dialog = false
    this.form.reset()
  }
}
