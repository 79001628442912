





























import { Component } from 'vue-property-decorator'
import Base from '@/application/models/Base'
import { Getter } from 'vuex-class'
import Hospital from '@/application/models/Hospital'
import { DataTableHeader } from 'vuetify'
import AddHospital from '@/components/manage/custom/AddHospital.vue'

@Component({
  components: { AddHospital }
})
export default class ManageHospitals extends Base {
  @Getter('hospitals', { namespace: 'hospitals' })
  readonly hospitals!: Hospital

  constructor () {
    super('manage.manage-hospital')
  }

  get tableHeader (): Array<DataTableHeader> {
    return [
      {
        text: this.getLangText('table.hospital_id'),
        value: 'id'
      },
      {
        text: this.getLangText('table.hospital_name'),
        value: 'name'
      },
      {
        text: this.getLangText('table.dataProvider_id'),
        value: 'patientDataProvider.id'
      },
      {
        text: this.getLangText('table.dataProvider_name'),
        value: 'patientDataProvider.name'
      }
    ]
  }
}
