






























































import { Component, Prop } from 'vue-property-decorator'
import Base from '@/application/models/Base'
import { DataTableHeader } from 'vuetify'
import { Getter } from 'vuex-class'
import Patient from '@/application/models/Patient'
import AddPatient from '@/components/common/AddPatient.vue'
import Tooltip from '@/components/common/Tooltip.vue'
import DischargePatient from '@/components/common/DischargePatient.vue'
import PatientVitalsSources from '@/application/models/PatientVitalsSources'

@Component({
  components: { DischargePatient, Tooltip, AddPatient }
})
export default class ManagePatient extends Base {
  @Getter('admitPatients', { namespace: 'patients' })
  readonly admitPatients!: Array<PatientVitalsSources>

  @Getter('patients', { namespace: 'patients' })
  readonly patients!: Array<Patient>

  @Prop({ required: false, default: false })
  readonly showArchived!: boolean

  constructor () {
    super('manage.manage-patient')
  }

  mounted () {
    console.log(this.patients)
  }

  get archivePatient (): Array<Patient> {
    const admitPatientIds = this.admitPatients.map(p => p.patient?.dataProviderPatientId)
    return this.patients.filter(p => !admitPatientIds.includes(p.dataProviderPatientId))
  }

  get tableHeadersAdmitPatient (): DataTableHeader[] {
    return [
      {
        text: this.getLangText('table.id'),
        value: 'patient.id'
      },
      {
        text: this.getLangText('table.external_id'),
        value: 'patient.dataProviderPatientId'
      },
      {
        text: this.getLangText('table.fullname'),
        value: 'patient.fullName'
      },
      {
        text: this.getLangText('table.admited'),
        value: 'timeFrom'
      },
      {
        text: this.getLangText('table.actions'),
        value: 'actions'
      }
    ]
  }

  get tableHeadersPatient (): DataTableHeader[] {
    return [
      {
        text: this.getLangText('table.id'),
        value: 'id'
      },
      {
        text: this.getLangText('table.external_id'),
        value: 'dataProviderPatientId'
      },
      {
        text: this.getLangText('table.fullname'),
        value: 'fullName'
      }
    ]
  }
}
