










































import Base, { Form } from '@/application/models/Base'
import { Component, Ref } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import PatientDataProvider from '@/application/models/PatientDataProvider'
import Tooltip from '@/components/common/Tooltip.vue'
import Hospital from '@/application/models/Hospital'

@Component({
  components: {
    Tooltip
  }
})
export default class AddHospital extends Base {
  // store
  @Getter('patientDataProvider', { namespace: 'patientDataProvider' })
  readonly patientDataProviders!: Array<PatientDataProvider>

  @Action('FETCH_PATIENTDATAPROVIDER_DATA', { namespace: 'patientDataProvider' })
  readonly fetchPatientdataproviderData!: () => Promise<Array<PatientDataProvider>>

  @Action('CREATE_HOSPITAL', { namespace: 'hospitals' })
  readonly createHospital!: (hospital: Hospital) => Promise<Hospital>

  // controll
  dialog = false
  @Ref('form')
  readonly form!: Form

  // data
  formData = {
    hospitalName: '',
    dataProvider: {
      id: '',
      name: ''
    }
  }

  constructor () {
    super('manage.add-hospital')
  }

  mounted () {
    this.fetchPatientdataproviderData()
  }

  get mapPatientDataProvider () {
    return this.patientDataProviders.map((patientDataProvider: PatientDataProvider) => {
      return {
        text: `${patientDataProvider.id} - ${patientDataProvider.name}`,
        value: patientDataProvider
      }
    })
  }

  send () {
    if (this.form.validate()) {
      this.dialog = false
      this.createHospital({
        id: '',
        name: this.formData.hospitalName,
        patientDataProvider: this.formData.dataProvider
      })
    }
  }

  close () {
    this.dialog = false
    this.form.reset()
  }
}
