





























import { Component } from 'vue-property-decorator'
import Base from '@/application/models/Base'
import { Action, Getter } from 'vuex-class'
import { DataTableHeader } from 'vuetify'
import AddHospital from '@/components/manage/custom/AddHospital.vue'
import PatientDataProvider from '@/application/models/PatientDataProvider'
import AddPatientDataProvider from '@/components/manage/custom/AddPatientDataProvider.vue'

@Component({
  components: { AddPatientDataProvider, AddHospital }
})
export default class ManagePatientDataProvider extends Base {
  @Getter('patientDataProvider', { namespace: 'patientDataProvider' })
  readonly patientDataProvider!: PatientDataProvider

  @Action('FETCH_PATIENTDATAPROVIDER_DATA', { namespace: 'patientDataProvider' })
  readonly fetchPatientdataproviderData!: () => Promise<Array<PatientDataProvider>>

  constructor () {
    super('manage.manage-patient-data-provider')
  }

  mounted () {
    this.fetchPatientdataproviderData()
  }

  get tableHeader (): Array<DataTableHeader> {
    return [
      {
        text: this.getLangText('table.patientDataProvider_id'),
        value: 'id'
      },
      {
        text: this.getLangText('table.patientDataProvider_name'),
        value: 'name'
      }
    ]
  }
}
