





























import { Component, Prop, Vue } from 'vue-property-decorator'
import Base from '@/application/models/Base'
import Patient from '@/application/models/Patient'
import Tooltip from '@/components/common/Tooltip.vue'

@Component({
  components: { Tooltip }
})
export default class DischargePatient extends Base {
  @Prop({ required: false, default: false })
  readonly bigBtn!: boolean

  @Prop({ required: true })
  readonly patient!: Patient

  // control
  dialog = false

  constructor () {
    super('discargePatient')
  }

  private close () {
    this.dialog = false
  }

  private send () {
    this.dialog = false
  }
}
